<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <v-icon>mdi-toy-brick-search</v-icon>
      Borçlu BB-Sakin Analiz
    </v-card-title>
    <v-card-subtitle>
      {{ $t("helper_text.analysis.income_type_house_user") }}
    </v-card-subtitle>
    <v-card-text v-if="clusterId">
      <v-row dense>
        <v-col>
          <v-form
            ref="form"
            @submit.prevent="handleFormSubmit"
            class="mb-3"
            :disabled="isLoading"
          >
            <v-row cols="12" dense>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Tahakkuk Başlangıç Tarih"
                  v-model="search.issues_on.min"
                  :rules="[
                    rules.maxDate(search.issues_on.min, search.issues_on.max),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Tahakkuk Bitiş Tarihi"
                  v-model="search.issues_on.max"
                  :rules="[
                    rules.minDate(search.issues_on.max, search.issues_on.min),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Son Ödeme Başlangıç Tarih"
                  v-model="search.last_payment_on.min"
                  :rules="[
                    rules.maxDate(
                      search.last_payment_on.min,
                      search.last_payment_on.max
                    ),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-datepicker
                  label="Son Ödeme Bitiş Tarihi"
                  v-model="search.last_payment_on.max"
                  :rules="[
                    rules.minDate(
                      search.last_payment_on.max,
                      search.last_payment_on.min
                    ),
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Borçlandırma Tipi"
                  :items="incomeTypeList"
                  v-model="search.income_type_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="İcradaki Borçlar"
                  :items="yesNoOptions"
                  v-model="search.has_enforcement"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Oturum Şekli"
                  :items="houseUserTypeList"
                  v-model="search.house_user_type_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select
                  label="Blok"
                  :items="blocks"
                  v-model="search.block_id"
                  multiple
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-select-project v-model="search.project_id" multiple />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-text-field v-model="search.description" label="Açıklama" />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-text-field
                  v-model="search.identity_number"
                  label="TC Kimlik No"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-tel-field
                  label="Telefon"
                  v-model="search.phone"
                  :rules="[
                    search.phone != ''
                      ? rules.minLength(search.phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>
              <v-col sm="3" lg="2">
                <rs-text-field v-model="search.address_legal" label="Adres" />
              </v-col>
              <v-col>
                <rs-form-buttons
                  :is-loading="isLoading"
                  @submit="handleFormSubmit"
                  hide-cancel
                  hide-save-close
                  saveLabel="Filtrele"
                />
              </v-col>
              <v-col
                sm="12"
                class="d-flex align-items-center justify-content-end mt-3"
              >
                <v-btn small color="pink" outlined @click="exportXLSX()">
                  <v-icon>mdi-file-table</v-icon>
                  Dışa Aktar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-simple-table dense id="printTable">
        <template v-slot:default>
          <thead>
            <tr class="bg-secondary bordered">
              <th class="text-left bg-secondary" rowspan="2">Blok</th>
              <th class="text-left bg-secondary" rowspan="2">BB No</th>
              <th class="text-left bg-secondary" rowspan="2">Sakin</th>
              <th
                class="text-left bg-secondary"
                v-for="it in incomeTypeListLocal"
                :key="it.id"
                colspan="3"
              >
                {{ it.name }}
              </th>
              <th colspan="4">Genel Toplam</th>
            </tr>
            <tr class="bg-secondary bordered">
              <template v-for="it in incomeTypeListLocal">
                <th
                  class="text-left bg-secondary p-0 text-center"
                  :key="it.id + 'Kalan'"
                >
                  Ana Borç Kalan
                </th>
                <th class="text-left bg-secondary" :key="it.id + 'Gecikme'">
                  Gecikme Kalan
                </th>
                <th class="text-left bg-secondary" :key="it.id + 'Bakiye'">
                  Toplam Kalan
                </th>
              </template>
              <td class="text-right bg-green">Avans</td>
              <th class="text-left bg-secondary p-0 text-center">
                Ana Borç Kalan
              </th>
              <th class="text-left bg-secondary">Gecikme Kalan</th>
              <th class="text-left bg-secondary">Toplam Kalan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in list" :key="row.id" class="bordered">
              <td
                v-if="index === 0 || list[index - 1].block !== row.block"
                :rowspan="
                  list.filter((v) => v.block_id === row.block_id).length
                "
              >
                {{ row.block }}
              </td>
              <td
                v-if="index === 0 || list[index - 1].house_id !== row.house_id"
                :rowspan="
                  list.filter((v) => v.house_id === row.house_id).length
                "
              >
                {{ row.door_number }}
              </td>
              <td>{{ row.resident }}</td>
              <template v-for="it in incomeTypeListLocal">
                <td class="text-right" :key="it.id + 'Kalan'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      row['it-' + it.id] && row['it-' + it.id].remaining_amount
                    "
                    :value="row['it-' + it.id].remaining_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Gecikme'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      row['it-' + it.id] &&
                      row['it-' + it.id].remaining_deferment_amount
                    "
                    :value="row['it-' + it.id].remaining_deferment_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Bakiye'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="
                      row['it-' + it.id] &&
                      row['it-' + it.id].remaining_total_amount
                    "
                    :value="row['it-' + it.id].remaining_total_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
              </template>
              <td class="text-right green lighten-4">
                <template v-if="row.totals.collecting_amount">
                  <rs-table-cell-number
                    :print="print"
                    price
                    :value="row.totals.collecting_amount"
                  />
                </template>
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right bg-secondary">
                <rs-table-cell-number
                  :print="print"
                  price
                  v-if="row.totals.remaining_amount"
                  :value="row.totals.remaining_amount"
                />
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right bg-secondary">
                <rs-table-cell-number
                  :print="print"
                  price
                  v-if="row.totals.remaining_deferment_amount"
                  :value="row.totals.remaining_deferment_amount"
                />
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right bg-secondary">
                <rs-table-cell-number
                  :print="print"
                  price
                  :value="row.totals.remaining_total_amount"
                />
              </td>
            </tr>
            <tr v-if="!isLoading && list.length === 0">
              <td colspan="7" class="text-center">
                <em>Filtrenize uygun veri bulunamadı.</em>
              </td>
            </tr>
            <tr v-if="isLoading">
              <td colspan="7" class="text-center">
                <v-progress-linear color="pink" indeterminate />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bg-secondary bordered">
              <th class="text-left" colspan="3">Toplamlar:</th>
              <template v-for="it in totals.income_type_sums">
                <td class="text-right" :key="it.id + 'Kalan'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="it.remaining_amount"
                    :value="it.remaining_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Gecikme'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="it.remaining_deferment_amount"
                    :value="it.remaining_deferment_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
                <td class="text-right" :key="it.id + 'Bakiye'">
                  <rs-table-cell-number
                    :print="print"
                    price
                    v-if="it.remaining_total_amount"
                    :value="it.remaining_total_amount"
                  />
                  <em class="text-muted" v-else>
                    <rs-table-cell-number :print="print" price :value="0.0" />
                  </em>
                </td>
              </template>
              <td class="text-right">
                <rs-table-cell-number
                  :print="print"
                  price
                  :value="totals.collecting_amount"
                />
              </td>
              <td class="text-right">
                <rs-table-cell-number
                  :print="print"
                  price
                  :value="totals.remaining_amount"
                />
              </td>
              <td class="text-right">
                <rs-table-cell-number
                  :print="print"
                  price
                  v-if="totals.remaining_deferment_amount"
                  :value="totals.remaining_deferment_amount"
                />
                <em class="text-muted" v-else>
                  <rs-table-cell-number :print="print" price :value="0.0" />
                </em>
              </td>
              <td class="text-right">
                <rs-table-cell-number
                  :print="print"
                  price
                  :value="totals.remaining_total_amount"
                />
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { filtersToURL, hasExport, hasForm } from "@/view/mixins";
import { utils, writeFile } from "xlsx";

export default {
  name: "IncomeTypeHouseUserList",
  mixins: [filtersToURL, hasExport, hasForm],
  computed: {
    ...mapGetters([
      "blocks",
      "clusterId",
      "houseUserTypeList",
      "incomeTypeList",
    ]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    const date = new Date();

    return {
      search: {
        issues_on: {
          min: new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
            .toJSON()
            .split("T")[0],
          max: new Date().toJSON().split("T")[0],
        },
        last_payment_on: {
          min: undefined,
          max: undefined,
        },
        income_type_id: [1],
        project_id: null,
        description: null,
        identity_number: null,
        address_legal: null,
        phone: null,
        has_enforcement: null,
        house_user_type_id: null,
        block_id: null,
      },
      print: false,
      isLoading: false,
      incomeTypeListLocal: [],
      list: [],
      totals: {},
      yesNoOptions: [
        {
          id: true,
          name: this.$t("yes"),
        },
        {
          id: false,
          name: this.$t("no"),
        },
      ],
    };
  },
  methods: {
    exportXLSX() {
      this.print = true;

      this.$nextTick(() => {
        try {
          const element = document.getElementById("printTable");
          const ws = utils.table_to_sheet(
            element.getElementsByTagName("TABLE")[0]
          );
          const wb = utils.book_new();
          utils.book_append_sheet(wb, ws, "Export");
          this.print = false;
          return writeFile(wb, "Robosay - Borçlu BB-Sakin Analiz.xlsx");
        } catch (e) {
          this.handleError(e);
          this.print = false;
        }
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        this.list = [];
        return;
      }

      this.setURLParams();
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (params.issues_on?.min === null) {
        params.issues_on.min = undefined;
      }
      if (params.issues_on?.max === null) {
        params.issues_on.max = undefined;
      }
      if (params.last_payment_on?.min === null) {
        params.last_payment_on.min = undefined;
      }
      if (params.last_payment_on?.max === null) {
        params.last_payment_on.max = undefined;
      }

      if (params.phone) {
        params.phone = params.phone.replace(/[^0-9+]/g, "");
      }

      params.cluster_id = this.clusterId;

      params.billed_on = {
        min: this.formData.start_date || undefined,
        max: this.formData.end_date || undefined,
      };

      params.per_page = undefined;

      return params;
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      this.list = [];
      this.totals = {};
      this.incomeTypeListLocal = [];

      const params = this.getParams();
      this.isLoading = true;

      this.$api
        .query(
          "income-analysis/income-type-sums-by-house-user/" + this.clusterId,
          { params }
        )
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            const list = [];

            for (const index in response.data.data) {
              const item = response.data.data[index];
              const row = Object.assign({}, item);

              for (const t in item.amounts) {
                const incomeTypeFound = this.incomeTypeListLocal.find((v) => {
                  return v.id === item.amounts[t].income_type_id;
                });

                if (incomeTypeFound === undefined) {
                  this.incomeTypeListLocal.push({
                    id: item.amounts[t].income_type_id,
                    name: item.amounts[t].income_type,
                  });

                  this.incomeTypeListLocal.sort((a, b) => {
                    if (a.id === b.id) return 0;
                    else if (a.id > b.id) return 1;
                    else return -1;
                  });
                }

                row["it-" + item.amounts[t].income_type_id] = {
                  income_type_id: item.amounts[t].income_type_id,
                  income_type: item.amounts[t].income_type,
                  remaining_amount: item.amounts[t].remaining_amount,
                  remaining_deferment_amount:
                    item.amounts[t].remaining_deferment_amount,
                  remaining_total_amount:
                    item.amounts[t].remaining_total_amount,
                };
              }

              list.push(row);
            }

            this.list = list;

            if (response.data.meta.totals) {
              this.totals = response.data.meta.totals;
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadURLParams();
  },
};
</script>

<style>
tr.bordered th,
tr.bordered td,
tr.bordered td.green.lighten-4 {
  border: 1px solid #0000001f !important;
}

tr > td.sticky,
tr > th.sticky {
  border: 1px solid #0000001f !important;
  left: 0;
  position: sticky;
}

tr > td.sticky.sticky-top,
tr > th.sticky.sticky-top {
  top: 0;
}
</style>
